import React, { useState, useEffect, useCallback } from 'react';
import '../styles/IPhoneViewer.css';
import demo1 from '../demos/1.PNG';
import demo2 from '../demos/2.PNG';
import demo3 from '../demos/3.PNG';

const IPhoneViewer = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [touchStart, setTouchStart] = useState(null);
  const images = [demo1, demo2, demo3];

  const changeImage = useCallback((newIndex) => {
    setCurrentImageIndex(newIndex);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      changeImage(currentImageIndex === images.length - 1 ? 0 : currentImageIndex + 1);
    }, 3000);

    return () => clearInterval(interval);
  }, [currentImageIndex, images.length, changeImage]);

  const handleTouchStart = (e) => {
    setTouchStart(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    if (!touchStart) return;

    const touchEnd = e.touches[0].clientX;
    const diff = touchStart - touchEnd;

    if (Math.abs(diff) > 50) {
      if (diff > 0) {
        changeImage(currentImageIndex === images.length - 1 ? 0 : currentImageIndex + 1);
      } else {
        changeImage(currentImageIndex === 0 ? images.length - 1 : currentImageIndex - 1);
      }
      setTouchStart(null);
    }
  };

  const handleTouchEnd = () => {
    setTouchStart(null);
  };

  const handleDotClick = (index) => {
    changeImage(index);
  };

  const handlePrevClick = () => {
    changeImage(currentImageIndex === 0 ? images.length - 1 : currentImageIndex - 1);
  };

  const handleNextClick = () => {
    changeImage(currentImageIndex === images.length - 1 ? 0 : currentImageIndex + 1);
  };

  return (
    <div className="iphone-viewer-container">
      <div className="viewer-with-arrows">
        <button className="arrow-button prev" onClick={handlePrevClick}>
          ‹
        </button>
        <div className="iphone-frame">
          <div 
            className="iphone-screen"
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <img 
              src={images[currentImageIndex]} 
              alt={`Demo ${currentImageIndex + 1}`}
              className="demo-image"
            />
          </div>
        </div>
        <button className="arrow-button next" onClick={handleNextClick}>
          ›
        </button>
      </div>
      <div className="dots-container">
        {images.map((_, index) => (
          <div 
            key={index}
            className={`dot ${index === currentImageIndex ? 'active' : ''}`}
            onClick={() => handleDotClick(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default IPhoneViewer; 