import './App.css';
import logo from './logo.png';
import IPhoneViewer from './components/IPhoneViewer';

function App() {
  return (
    <div className="App">
      <div className="container">
        <img src={logo} className="logo" alt="Logo" />
        <h1 className="tagline">Turn any moment into a language learning opportunity <br />for FREE! 🎉</h1>
        <IPhoneViewer />
        <h2 className="sub-tagline">Visual Language Learning with AI - Capture Vocabularies in a Snap!</h2>
        <h2 className="sub-text">Support 7 languages: Spanish, Chinese, Hindi, French, Italian, Japanese, and Korean.</h2>
        <h2 className="sub-title">📸 Snap and Learn</h2>
        <h2 className="sub-text">Simply upload or capture a picture, AI will automatically pick and tag relevant words for you!</h2>
        <h2 className="sub-title">💾 Save and Share</h2>
        <h2 className="sub-text">Download your picture with tags and vocabulary list for easy review and sharing!</h2>
        <h2 className="try-it-out">Try it out on iOS now!</h2>
        <a 
          href="https://apps.apple.com/app/6738285192" //Change this to the app store link
          target="_blank" 
          rel="noopener noreferrer"
          className="app-store-button"
        >
          <img 
            src="https://developer.apple.com/app-store/marketing/guidelines/images/badge-download-on-the-app-store.svg"
            alt="Download on the App Store"
          />
        </a>
        <div className="waitlist-container">
          <span className="waitlist-text">Want to use Piñata on other platform? Or interested in learning other languages?</span>
          <a 
            href="https://forms.gle/JfXFTZomAyEMRCis8"
            target="_blank"
            rel="noopener noreferrer"
            className="waitlist-button"
          >
            Join the waitlist! 🤩
          </a>
        </div>
        <div className="footer">
          <h2 className="footer-text">© 2024 Piñata. All rights reserved.</h2>
        </div>
      </div>
    </div>
  );
}

export default App;
